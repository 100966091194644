import React, { Component } from 'react';
import StoryblokClient, { StoryData } from 'storyblok-js-client';
import { Helmet } from 'react-helmet';
import { blokToComponent, getComponent } from '../components';
import {
  DomService, EditorService, LanguageService, StoryblokService,
} from '../services';
import { ExternalDataState } from './types';
import { Analytics } from '../components/custom/analytics';
import { Hotjar } from '../components/custom/hotjar';
import { MetaRobotsContent } from '../components/custom/meta-robots-content';
import { HelmetHead } from '../components/custom/head';
import { retry } from '../utils/retry';
import { Tracking } from '../tracking';
import { getTrackingContextProps } from '../tracking/context';
import { RocheHeader } from '../components/custom/roche-header';

const mockedStory = {
  uuid: 'detail-page',
  content: {
    component: 'page',
    _uid: 'detail-page',
    hide_share: false,
  },
  alternates: [],
  created_at: '',
  full_slug: '',
  group_id: '',
  id: -1,
  is_startpage: false,
  meta_data: '',
  name: 'Detail Page',
  parent_id: null,
  position: 0,
  published_at: null,
  first_published_at: null,
  slug: 'detail',
  sort_by_date: null,
  tag_list: [],
};

const RocheGlobalConfig = getComponent('roche-global-config') as React.ElementType;
export class ExternalData extends Component<Record<string, unknown>, ExternalDataState> {
  private storyblokClient: StoryblokClient;

  private mutationObserverCallback: MutationCallback = () => this.setState({
    ...this.state,
    isDomReady: document.documentElement.lang === this.state.locale,
  });

  private observer: MutationObserver;

  public constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);

    this.state = {
      isLoading: true,
      isDomReady: false,
    } as ExternalDataState;
  }

  public async componentDidMount(): Promise<void> {
    this.observer = new MutationObserver(this.mutationObserverCallback);
    this.observer.observe(document.documentElement, { attributes: true });
    this.storyblokClient = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });
    await this.loadGlobalData();
    DomService.activateConsentScript();
  }

  public componentWillUnmount(): void {
    this.observer.disconnect();
  }

  private async loadGlobalData(): Promise<void> {
    const queryParams = {
      version: 'published',
      resolve_relations: StoryblokService.getConfig().options?.resolveRelations?.split(',') || [],
      resolve_links: StoryblokService.getConfig().options?.resolveLinks || '',
    } as unknown;

    const lang = LanguageService.getActiveLanguage();
    const locales = await retry(() => LanguageService.getAllLocals(this.storyblokClient), 5, 100);

    await EditorService.loadGlobalComponents(
      this.storyblokClient,
      (...args) => this.setState(
        ...args,
        () => {
          const {
            content: {
              emergency_banner: emergencyBannerUuid,
              footer: footerUuid,
              home: homeUuid,
              navigation: navigationUuid,
              on_click_notice: onClickNoticeUuid,
              search: searchUuid,
            },
          } = this.state.globalComponents;

          [
            [emergencyBannerUuid, 'emergencyBanner'],
            [footerUuid, 'footer'],
            [homeUuid, 'home'],
            [navigationUuid, 'navigation'],
            [onClickNoticeUuid, 'onClickNotice'],
            [searchUuid, 'search'],
          ]
            .forEach(([uuid, stateKeyName]) => EditorService.loadGlobalComponent(
              uuid,
              stateKeyName,
              this.storyblokClient,
              this.setState,
              queryParams,
              lang,
            ));
        },
      ),
    );

    this.setState({
      ...this.state,
      ...DomService.getGlobalConfig(
        {
          ...mockedStory,
          allSpaceLocales: locales,
          lang,
        } as StoryData,
      ),
      isLoading: false,
    });
  }

  public render(): JSX.Element {
    const {
      emergencyBanner,
      footer,
      onClickNotice,
      home,
      isLoading,
      isDomReady,
      locale,
      navigation,
      ...globalConfig
    } = this.state;
    const canShowStockPrice = !home?.content.hide_stock_price;
    const disableIndexing = process.env.GATSBY_BLOCK_SE_INDEXING === 'true' ?? false;
    const shouldShowHeader = !navigation?.content.remove_header;
    const canShowNavigation = !navigation?.content.hide_navigation && navigation;
    const shouldFixHeader = navigation?.content.fixed_header;
    const displayMicrositeHeader = navigation?.content.microsite_header;
    const canShowMicrositeHeaderTitle = navigation?.content.microsite_title?.[0];
    return (
      <Tracking {...getTrackingContextProps(this.state)}>
        <Helmet title='Roche - Doing now what patients need next' htmlAttributes={{ lang: locale }} />
        <HelmetHead lang={locale} />
        {
          (isDomReady && !isLoading) && <>
            <Hotjar />
            {disableIndexing && <MetaRobotsContent />}
            <Analytics />
            <RocheGlobalConfig locale={locale} {...globalConfig}></RocheGlobalConfig>

            {shouldShowHeader && navigation
              && <RocheHeader
                home={LanguageService.getHomePageUrl(home)}
                navigation={navigation?.content as any}
                showNavigation={!!canShowNavigation}
                isMicrosite={displayMicrositeHeader}
                isFixed={shouldFixHeader}
                alternates={this.state.alternates}>

                {canShowMicrositeHeaderTitle
                  && blokToComponent({ blok: canShowMicrositeHeaderTitle, slot: 'microsite-title', getComponent })
                }
              </RocheHeader>}

            {React.Children.map(
              this.props.children,
              (child: React.ReactElement) => React.cloneElement(child, { locale }),
            )}
            {footer
              && blokToComponent({ blok: { ...footer.content, canShowStockPrice }, getComponent })}
            {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
            {emergencyBanner && blokToComponent({ blok: emergencyBanner.content, getComponent })}
            <roche-fouc-preventer />
          </>
        }
      </Tracking>
    );
  }
}
